<script>
// import Swal from 'sweetalert2';
import VTextbox from '@/components/Textbox.vue';
import VButton from '@/components/Button.vue';
import VCards from '@/components/Cards.vue';
import VSteps from '@/components/Steps.vue';
import Swal from 'sweetalert2';

export default {
  name: 'CelRecharge',
  components: {
    VTextbox,
    VButton,
    VCards,
    VSteps,
  },
  data() {
    return {
      celular: '',
      confirmaCelular: '',
      valor: '',
      valores: [],
      idContaCartaoDebitado: null,
      operadoras: [],
      ddd: null,
      operadoraId: '',
      orderId: ''
    };
  },
  beforeMount() {
    this.getAvaliableDealers()
  },
  watch: {
    celular() {
      this.operadoraId = ''
    },
    confirmaCelular() {
      this.operadoraId = ''
    },
    operadoraId(val) {
      const ddd = this.celular.match(/^\(0(..)\)/);
      this.ddd = ddd && ddd[1];
      const phoneNumber = this.celular.match(/\(.+\) (.+)/)[1].replace(/[^\d]/g, '');
      if (!val && !this.ddd && !this.celular) return false
      this.$store.commit('formLoading', true);
      this.$http.post('/transfers/phone-recharge', {
        ddd: this.ddd,
        phoneNumber,
        dealerCode: this.operadoraId
      })
        .then(({ data }) => {
          if (data.orderId) {
            this.orderId = data.orderId;
          }
          data.options.forEach(element => {
            if (element.fixed_amounts) {
              this.valores = element.fixed_amounts
            }
          });
          this.valores.forEach(element => {
            element.visibleValue = `R$ ${element.amount.slice(0, -2)},00`;
          })
          this.$store.commit('formLoading', false);
        })
    }
  },
  methods: {
    async sendForm() {
      if (this.$store.state.formLoading) return false

      if (!this.celular || !this.operadoraId || !this.orderId || this.valor === '') {
        this.$store.commit('message', { type: 'error', text: 'Preencha todos os campos' });
        return false;
      }
      this.$store.commit('formLoading', true)
      await this.$validator.validateAll().then(async () => {
        let amountKeyId = 0;
        this.valores.forEach(element => {
          if (element.amount === this.valor) {
            amountKeyId = element.amountKey || 0;
          }
        });
        const phoneNumber = this.celular.match(/\(.+\) (.+)/)[1].replace(/[^\d]/g, '');
        await this.$http.post('/transfers/phone-recharge-confirm', {
          orderId: this.orderId,
          accountId: this.idContaCartaoDebitado,
          ddd: `${this.ddd}`,
          phoneNumber,
          dealerCode: this.operadoraId,
          amount: this.valor,
          amountKey: amountKeyId
        })
          .then(({ data }) => {
            console.log(data)
            this.$store.commit('message', { type: 'info', text: 'Recarga realizada com sucesso.' });
            this.valor = ''
            this.celular = ''
            this.confirmaCelular = ''
            this.$store.commit('formLoading', false)
          })
          // eslint-disable-next-line no-unused-vars
          .catch((err) => {
            // this.$store.commit('message', { type: 'error', text: err.message });
            this.$store.commit('formLoading', false)
          })
      })
    },
    nextStep(card, balance) {
      this.saldo = balance
      this.idContaCartaoDebitado = card.idConta.toString()
    },
    getAvaliableDealers() {
      this.$http.get('/transfers/phone-dealers')
        .then(({ data }) => {
          this.operadoras = data.dealers;
        })
        .catch((err) => {
          this.$store.commit('message', { type: 'error', text: err.message });
        })
    },
    async permitedOperation() {
      await this.$http.get('/utils/check-restricao')
        .catch((data) => {
          console.log('data', data)
          // show message and redirect to home
          Swal.fire({
            type: 'error',
            title: data.message || 'Transação não permitida nesse horário',
            confirmButtonText: 'Ok',
            customClass: {
              title: 'px-5'
            },
          }).then(() => {
            this.$router.push('/')
          }).finally(() => {
            this.$router.push('/')
          })
        })
    }
  },
  computed: {
    availableCards() {
      return this.$store.state.availableCards;
    }
  },
  async mounted() {
    await this.permitedOperation()
  },
  // beforeCreate() {
  //   Swal.fire({
  //     html: `
  //       <div style='text-align: left;color: #6c757d!important; font-size: medium;'>
  //         <h3 style='font-size: 1.75rem;'>Olá!</h3>
  //           Operação não disponível no momento. Agradecemos a compreensão.
  //           <br />
  //           <br />
  //           <br />
  //           <center>Equipe OnlyPay</center>
  //       </div>
  //     `,
  //     type: 'warning',
  //     showConfirmButton: true,
  //     confirmButtonText: 'Voltar',
  //     allowOutsideClick: false,
  //     allowEscapeKey: false,
  //     allowEnterKey: false
  //   })
  //   this.$router.push('/')
  // }
  // mounted() {
  //   Swal.fire(
  //     'AVISO!',
  //     `<p style="text-align:left;">
  //     Caro Usuário,<br><br>
  //     Site disponível para consulta de saldo e desbloqueio do seu cartão.<br><br>
  //     Demais funcionalidades como pagamento de boleto, transferência entre cartões, transferência e recarga de celular estarão disponíveis em breve.<br><br>
  //     Equipe OnlyPay.
  //     </p>`,
  //     'info'
  //   )
  // }
};
</script>

<template>
  <div class="wrap">
    <v-steps :current="idContaCartaoDebitado ? 2 : 1" :length="2" class="transfer__header"/>
    <div v-if="!idContaCartaoDebitado">
      <p>
        A partir das 20h até 8h do próximo dia útil não haverá processamento.
        <br>
        <br>
        <strong v-if="this.availableCards">Selecione o cartão que deseja que seja debitado o valor da recarga.</strong>
        <strong v-else class="unavailableCards">Não há cartões disponíveis para este tipo de transação.</strong>
      </p>
      <br>
      <v-cards @click="nextStep" :showActions="false" :showBalance="true"/>
    </div>
    <form v-else @submit.prevent="sendForm" class="form-charge">
      <v-textbox
        v-model="celular"
        data-vv-as="Celular"
        data-vv-scope="celular"
        label="Número do celular:"
        name="celular.celular"
        placeholder="(000) 0 0000-0000"
        :mask="['(0##) ####-####', '(0##) # ####-####']"
        type="text"
        required/>
      <v-textbox
        v-model="confirmaCelular"
        data-vv-as="Confirma celular"
        data-vv-scope="celular"
        label="Confirme o número do celular:"
        name="celular.confirmaCelular"
        placeholder="(000) 0 0000-0000"
        :mask="['(0##) ####-####', '(0##) # ####-####']"
        :validate="`required|confirmed:celular.celular`"
        type="text"
        required
        />
        <div class="textbox">
          <label class="textbox__label">Selecione a operadora:</label>
          <select v-model="operadoraId" :disabled="(operadoras.length === 0) || (celular == '' && confirmaCelular == '')" class="textbox__input" required>
            <option value="">{{operadoras.length === 0 ? 'Preencha o telefone' : 'Selecione' }}</option>
            <option
              :value="operadora.code"
              v-for="operadora in operadoras"
              :key="operadora.code">
              {{operadora.name}}
            </option>
          </select>
        </div>

        <div class="textbox">
          <label class="textbox__label">Valor da recarga:</label>
          <select v-model="valor" :disabled="valores.length === 0" class="textbox__input" required>
            <option value="">{{valores.length === 0 ? 'R$ 0,00' : 'Selecione' }}</option>
            <option
              :value="valor.amount"
              v-for="valor in this.valores"
              :key="valor.amount">
              {{valor.visibleValue}}
            </option>
          </select>
        </div>
        <v-button label="Recarregar celular" class="block__form__actions__submit"/>
    </form>
  </div>
</template>

<style lang="postcss" scoped>

.unavailableCards {
  color: red;
}
.block__form__actions__submit{
  margin-top: 2rem;
}
.wrap {
  position: relative;
  @media (max-width: 991px) {
    background-color: #fff;
    /* padding-top: 5vh; */
  }
}
.form-charge {
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 5rem;
  @media (min-width: 992px) {
    padding-top: 0;
    max-width: 500px;
  }
}
</style>
